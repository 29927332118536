import { lazy } from 'react';

import { config } from '@/config';

const Root = lazy(() => import('./Root'));

(async () => {
  if (window.location.href.startsWith(config.login.redirectUri)) {
    const { handleLoginRedirect } = await import('@/configuration/login/redirect');
    handleLoginRedirect();
  } else {
    const { main } = await import('@/configuration');
    const { createRoot } = await import('react-dom/client');

    const renderApplication = () => {
      createRoot(document.getElementById('root') as HTMLElement).render(<Root />);
    };
    main(renderApplication);
  }
})();
