const asBool = (value: string | undefined): boolean => value === 'true';

const getRedirectURI = (uri: string) => {
  let redirectTo = uri;

  if (typeof window !== 'undefined') {
    const host = window.location.host;

    if (host.startsWith('preview-')) {
      const hash = host.substring(host.indexOf('preview-') + 8, 16);

      redirectTo = `https://preview-${hash}.mannow-dev.rio.cloud/mannow/auth`;
    }
  }

  return redirectTo;
};

export const config: TConfigState = {
  backend: {
    AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
    MENU_SERVICE: import.meta.env.VITE_MENU_SERVICE,
    MANNOW_FM: import.meta.env.VITE_MANNOW_FEATURES_API_URL,
    MANNOW_VM: import.meta.env.VITE_MANNOW_VEHICLES_API_URL,
    MANNOW_UM: import.meta.env.VITE_MANNOW_USERS_API_URL,
    MANNOW_CMS: import.meta.env.VITE_MANNOW_CMS_URL,
    MANNOW_CMS_CONTENT: import.meta.env.VITE_MANNOW_CMS_CONTENT_URL,
    MANNOW_DEMO_FLEET_CONTENT: import.meta.env.VITE_MANNOW_DEMO_FLEET_CONTENT_URL,
    MANNOW_MUM: import.meta.env.VITE_MANNOW_MAP_UPDATE_MAN_API_URL,
    MANNOW_SUBM: import.meta.env.VITE_MANNOW_SUBSCRIPTION_MANAGER_URL,
    MANNOW_INSTALLATIONS: import.meta.env.VITE_MANNOW_INSTALLATIONS_API_URL,
    MANNOW_RECOMMENDATION_ENGINE: import.meta.env.VITE_MANNOW_RECOMMENDATION_ENGINE_API_URL,
    RIO_TCS: import.meta.env.VITE_RIO_TERMS_AND_CONDITIONS_URL,
    RIO_ASSET: import.meta.env.VITE_RIO_ASSET_URL,
    RIO_BILLING_DATA: import.meta.env.VITE_RIO_MARKETPLACE_BILLING_DATA_URL,
    RIO_PAYMENT_METHODS: import.meta.env.VITE_RIO_MARKETPLACE_PAYMENT_METHODS_URL,
    RIO_TAGS: import.meta.env.VITE_TAGS_SERVICE_URL,
    RIO_CONTRACTS: import.meta.env.VITE_RIO_MARKETPLACE_CONTRACTS_URL,
    RIO_BOOKINGS: import.meta.env.VITE_RIO_MARKETPLACE_BOOKINGS_URL,
  },
  googleAnalytics: {
    GTM_ID: import.meta.env.VITE_GTM_ID,
    GTM_AUTH: import.meta.env.VITE_GTM_AUTH,
    GTM_PREVIEW: import.meta.env.VITE_GTM_PREVIEW,
  },
  homeRoute: import.meta.env.VITE_HOME_ROUTE,
  id: import.meta.env.VITE_ID,
  login: {
    authority: import.meta.env.VITE_LOGIN_AUTHORITY,
    clientId: import.meta.env.VITE_LOGIN_CLIENT_ID,
    oauthScope: [
      'openid',
      'profile',
      'email',
      'user-managment.read',
      'asset-administration.read',
      'tags.read',
      'marketplace.read',
      'mannow.read',
      'mannow.write',
    ],
    mockAuthorization: false, // import.meta.env.DEV,
    mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
    preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
    redirectUri: getRedirectURI(import.meta.env.VITE_LOGIN_REDIRECT_URI),
    silentRedirectUri: getRedirectURI(import.meta.env.VITE_LOGIN_REDIRECT_URI),
  },
  serviceName: 'mannow-frontend',
  serviceVersion: import.meta.env.VITE_RELEASE_VERSION,
  serviceEnvironment: import.meta.env.MODE,
  enableMockServer: import.meta.env.DEV,
  logoutUri: import.meta.env.VITE_LOGOUT_URI,
  sentryToken: import.meta.env.VITE_SENTRY_DSN,
  sentryModuleName: 'starterTemplate',
  isBackSoonEnabled: asBool(import.meta.env.VITE_APP_BACK_SOON_ENABLED),
  showWarningMessage: asBool(import.meta.env.VITE_APP_SHOW_WARNING_BANNER),
  configCatKey: import.meta.env.VITE_CONFIGCAT_SDK_KEY,
  datadogClientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  datadogTagAwsAccountId: import.meta.env.VITE_DATADOG_TAG_AWS_ACCOUNT_ID,
  datadogTagAwsAccountName: import.meta.env.VITE_DATADOG_TAG_AWS_ACCOUNT_NAME,
  datadogTagTeamIdentifier: import.meta.env.VITE_DATADOG_TAG_TEAM_IDENTIFIER,
};
